<template>
  <Toast />
  <Menu />

  <div id="invoiceApp" class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      <strong>New Sale</strong>
    </h6>
    <hr style="height: 0.5px; margin-bottom: 30px" />
    <form @submit.prevent="createPost" @keydown.enter.prevent>
      <div class="row justify-content-center">
        <div class="col-md-2">
          <div class="form-group">
            <label for="invoiceDate" class="text-bold"
              >Invoice Date &nbsp;</label
            >
            <span class="text-info">
              <small class="text-dark">{{ invoiceModel.sale_date }}</small>
            </span>
            <input
              type="date"
              id="invoiceDate"
              v-model="invoiceModel.sale_date"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="customerId" class="text-bold">Customer</label>
            <!-- <select id="customerId" v-model="invoiceModel.customer_id" class="form-control" required>
						<option v-bind:value="c.id" v-for="c in customerModel" :key="c.id">{{c.customer_name}} {{c.customer_surname}}</option>
					</select> -->
            <SelectCustomer
              id="customerId"
              :customer-model="customerModel"
              v-model="invoiceModel.customer_id"
              :selected="invoiceModel.customer_id"
            >
            </SelectCustomer>
          </div>
        </div>

        <div class="col-md-2">
          <label for="store">Store</label>
          <select
            id="store"
            v-model="invoiceModel.store_id"
            class="form-control"
            @change="storeChange"
            required
          >
            <option v-bind:value="s.id" v-for="s in storeModel" :key="s.id">
              {{ s.store_name }}
            </option>
          </select>
        </div>

        <div class="col-md-2">
          <label for="sale_type">Sale Type</label>
          <select
            @change="changeSaleType()"
            id="sale_type"
            v-model="invoiceModel.sale_type_id"
            class="form-control"
            required
          >
            <option
              v-bind:value="s.sale_type_id"
              v-for="s in saleTypeModel"
              :key="s.sale_type_id"
            >
              {{ s.sale_type_name }}
            </option>
            <option value="0">Proforma</option>
          </select>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="sale_type">Product Type</label>
          <select
            id="sale_type"
            v-model="invoiceModel.product_type"
            class="form-control"
            required
          >
            <option value="Jewel">Jewel</option>
            <option value="Watch">Watch</option>
            <option value="Jewel&Watch">Jewel&Watch</option>
          </select>
        </div>

        <div class="col-md-2">
          <label for="Sale Tag">Sale Tag</label>
          <v-select
            :options="saleTagModel"
            label="name"
            v-model="invoiceModel.tags"
            :reduce="(tag) => tagConverter(tag.id)"
            :multiple="true"
          />
        </div>
        <div class="col-md-5">
          <label for="description">Description</label>
          <input
            @keydown.enter.prevent
            type="text"
            id="description"
            v-model="invoiceModel.description"
            class="form-control"
          />
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-2">
          <label for="currency">Currency</label>
          <select
            id="currency"
            v-model="invoiceModel.currency"
            class="form-control"
          >
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="TRY">TRY</option>
            <option value="AED">AED</option>
          </select>
          <span class="text-muted">
            Exchange Rate:
            <span class="text-danger fw-bold">{{
              exchangeRate.toFixed(2)
            }}</span>
          </span>
        </div>
        <div class="col-md-2">
          <label for="taxRate">Tax Rate (%)</label>
          <input
            @keydown.enter.prevent
            type="number"
            id="taxRate"
            v-model="invoiceModel.tax_rate"
            class="form-control"
            min="0"
            @change="taxRateChange"
          />
        </div>
        <div class="col-md-2">
          <!-- <label for="taxAmount">Tax Amount</label>
          <input
            @keydown.enter.prevent
            type="number"
            id="taxAmount"
            v-model="invoiceModel.tax_amount"
            class="form-control"
          /> -->
        </div>
        <div class="col-md-3"></div>
      </div>

      <!-- Expenses -->
      <div class="row justify-content-center mb-4">
        <div class="col-md-9">
          <h5
            class="text-left text-info text-dark"
            style="margin-top: 30px; font-size: 15px"
          >
            Expenses
          </h5>
          <table id="expense-table" class="table table-bordered">
            <thead>
              <tr class="text-center">
                <th>on Client / on Company</th>
                <th>Expense Type</th>
                <th>Description</th>
                <th>Expense Amount</th>
                <th>
                  <button
                    type="button"
                    v-on:click="addExpense"
                    class="btn btn-success btn-sm"
                  >
                    <span area-hidden="true">&plus;</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in invoiceModel.expense" :key="i">
                <td width="20%">
                  <div class="form-group">
                    <select
                      id="client-company"
                      v-model="d.client_or_company"
                      class="form-control"
                    >
                      <option value="client" selected>Client</option>
                      <option value="company">Company</option>
                    </select>
                  </div>
                </td>
                <td width="20%">
                  <div class="form-group">
                    <select
                      id="expenses"
                      v-model="d.expense_type"
                      class="form-control"
                    >
                      <option
                        v-bind:value="e.id"
                        v-for="e in expenseTypeModel"
                        :key="e.id"
                      >
                        {{ e.expense_name }}
                      </option>
                    </select>
                  </div>
                </td>

                <td width="20%">
                  <div class="form-group">
                    <input
                      type="text"
                      @keydown.enter.prevent
                      id="expense-description"
                      v-model="d.description"
                      class="form-control"
                    />
                  </div>
                </td>

                <td width="10%">
                  <div class="form-group">
                    <input
                      type="number"
                      step=".01"
                      @keydown.enter.prevent
                      id="expense-amount"
                      v-model="d.expense_amount"
                      class="form-control"
                    />
                  </div>
                </td>

                <td width="5%">
                  <button
                    type="button"
                    v-on:click="removeExpense(i)"
                    class="btn btn-danger btn-sm"
                    tabindex="99"
                  >
                    <span aria-hidden="true">&minus;</span>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td><strong>Items:</strong> &Sigma; {{ getExpenseCount() }}</td>
                <td colspan="1" class="text-center">
                  <strong>Totals:</strong>
                </td>
                <td>
                  <span class="text-info text-bold text-danger"
                    >&dollar; {{ getExpenseTotal() }}</span
                  >
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <!-- Expenses END -->

      <!-- Invoice Products -->
      <div class="row justify-content-center">
        <div class="col-md-11">
          <div class="row">
            <div class="col-md-2 align-self-center">
              <h5
                class="text-left text-info text-dark"
                style="margin-top: 30px; font-size: 15px"
              >
                Products
              </h5>
            </div>

            <!-- <div class="col-md-2 offset-md-4 offset-0 align-self-center float-md-end" > -->
            <!-- v-show="invoiceModel.sale_type_id > 0"  -->
            <!-- </div> -->
            <div
              class="col-md-5 offset-md-3 d-flex align-self-center justify-content-center float-md-end"
            >
              <!-- v-show="invoiceModel.sale_type_id > 0" -->
              <div
                class=""
                v-show="
                  invoiceModel.sale_type_id > 0 ||
                  invoiceModel.sale_type_id === '0'
                "
                style="display: inline-block"
              >
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value="tag"
                    v-model="priceType"
                    @change="multiplierExpand"
                    id="flexRadioDefault1"
                  />
                  <label class="form-check-label" for="flexRadioDefault1">
                    Tag Price
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value="eur"
                    id="flexRadioDefault2"
                    v-model="priceType"
                    @change="multiplierExpand"
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    List Price (EUR)
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value="usd"
                    id="flexRadioDefault2"
                    v-model="priceType"
                    @change="multiplierExpand"
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    List Price (USD)
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value="aed"
                    id="flexRadioDefault2"
                    v-model="priceType"
                    @change="multiplierExpand"
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    List Price (AED)
                  </label>
                </div>
              </div>
              <button
                v-show="
                  invoiceModel.sale_type_id > 0 ||
                  invoiceModel.sale_type_id === '0'
                "
                class="btn btn-danger"
                type="button"
                style="
                  margin-right: 10px;
                  margin-left: 20px;
                  margin-bottom: 10px;
                  height: 42px !important;
                "
                @click="retriveLastSaleData"
              >
                Fetch Last Data
              </button>

              <Button
                v-show="
                  invoiceModel.sale_type_id > 0 ||
                  invoiceModel.sale_type_id === '0'
                "
                label="Multi Product"
                icon="pi pi-external-link"
                @click="openBasic"
                style="margin-bottom: 10px; height: 42px !important"
              />

              <Dialog
                header="Multi Product Add"
                v-model:visible="displayBasic"
                :style="{ width: '35vw' }"
              >
                <textarea
                  v-model="multiProduct"
                  :disabled="multiDisabled"
                  :style="{ width: '32vw', height: '10vw' }"
                ></textarea>

                <input
                  class="form-control"
                  type="file"
                  @change="uploadMutliExcel"
                  id="formFile"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <small class="form-text text-muted"
                  >Excel should have 3 columns. These are:"reference
                  no","price","description". It will not work if the column
                  headings are not correct.
                  <a href="#" @click="downloadSaleTemplate()"
                    >Download template.</a
                  ></small
                >

                <template #footer>
                  <Button
                    label="Upload Excel"
                    class="p-button-success"
                    @click="importJson"
                    autofocus
                    style="margin-right: 20px"
                    v-if="sheetData.length > 0"
                  />

                  <Button
                    label="No"
                    icon="pi pi-times"
                    @click="closeBasic"
                    class="p-button-text"
                  />
                  <Button
                    label="Yes"
                    icon="pi pi-check"
                    @click="multiProductAdd"
                    autofocus
                  />
                </template>
              </Dialog>
            </div>

            <div class="col-md-2 align-self-center">
              <input
                type="number"
                @keydown.enter.prevent
                step="any"
                class="form-control"
                placeholder="Multiplier "
                v-model="autoMultiplier"
                @change="multiplierExpand"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-9">
          <div
            v-if="
              typeof multiErrorList !== 'undefined' && multiErrorList.length > 0
            "
          >
            <p>Error List: {{ multiErrorList }}</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-9">
          <div
            v-if="
              typeof multiSuccessList !== 'undefined' &&
              multiSuccessList.length > 0
            "
          >
            <p>Sucess List: {{ multiSuccessList }}</p>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-11">
          <table id="invoice-table" class="table table-bordered">
            <thead>
              <tr class="text-center">
                <th>#</th>
                <th>Photo</th>
                <th>Product</th>
                <th>Multiplier</th>
                <th v-if="priceType === 'tag'">Tag Price</th>
                <th v-else-if="priceType === 'usd'">List Price (USD)</th>
                <th v-else-if="priceType === 'aed'">List Price (AED)</th>
                <th v-else>List Price (EUR)</th>
                <th>Description</th>
                <th v-show="showOriginalPrice">Original Price</th>
                <th>Sale Price</th>
                <th v-if="hasPermission((roles = ['limited_seller']))">
                  Net Profit
                </th>
                <th v-if="priceType == 'tag'">Sale/Tag</th>
                <th v-else-if="priceType == 'usd'">Sale/List (USD)</th>
                <th v-else-if="priceType == 'aed'">Sale/List (AED)</th>
                <th v-else>Sale/List (EUR)</th>

                <th>
                  <button
                    type="button"
                    v-on:click="addItem"
                    class="btn btn-success btn-sm"
                  >
                    <span area-hidden="true">&plus;</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in invoiceModel.sale" :key="i">
                <td width="2%" class="text-center">{{ i + 1 }}</td>
                <td width="10%">
                  <div class="form-group">
                    <Image
                      v-bind:src="(photo = getPhoto(d.product_id, i))"
                      alt="Image"
                      width="80"
                      preview
                    />
                  </div>
                </td>
                <td width="15%">
                  <div class="form-group">
                    <SelectProduct
                      v-if="invoiceModel.sale_type_id === '0'"
                      :productModel="productModel"
                      v-model="d.product_id"
                      :selected="d.product_id"
                      :outProducts="outProducts"
                      :key="selectKey"
                      :extraParams="newProductParams"
                      :active="null"
                    />

                    <SelectProduct
                      :active="false"
                      v-if="invoiceModel.sale_type_id === 9"
                      :productModel="productModel"
                      v-model="d.product_id"
                      :selected="d.product_id"
                      :outProducts="outProducts"
                      @change="selectProductChange(d.product_id)"
                      :key="selectKey"
                      :extraParams="newProductParams"
                    />
                    <SelectProduct
                      v-if="
                        invoiceModel.sale_type_id != 9 &&
                        invoiceModel.sale_type_id != '0'
                      "
                      :productModel="productModel"
                      v-model="d.product_id"
                      :selected="d.product_id"
                      :outProducts="outProducts"
                      :key="selectKey"
                      :extraParams="newProductParams"
                    />
                  </div>
                </td>
                <td width="5%">
                  <div class="form-group">
                    <input
                      type="number"
                      step="any"
                      id="invoice-multiplier"
                      v-model="d.multiplier"
                      @change="calculatePrice(d.product_id, i)"
                      class="form-control"
                    />
                  </div>
                </td>
                <td width="10%">
                  <div class="form-group">
                    <span
                      >&dollar;
                      {{ (ticketPrice = getTicket(d.product_id, i)) }}</span
                    >
                  </div>
                </td>
                <td width="20%">
                  <div class="form-group">
                    <input
                      type="text"
                      id="invoice-description"
                      v-model="d.description"
                      class="form-control"
                    />
                  </div>
                </td>
                <td width="10%" v-show="showOriginalPrice">
                  <div class="form-group">
                    <input
                      type="number"
                      step="0.01"
                      placeholder="0.00"
                      id="Price"
                      v-model="d.original_price"
                      class="form-control"
                      @change="calculatePricewCurrency(d.product_id, i)"
                    />
                  </div>
                </td>
                <td width="10%">
                  <div class="form-group">
                    <input
                      type="number"
                      step="0.01"
                      placeholder="0.00"
                      id="Price"
                      v-model="d.price"
                      class="form-control"
                    />
                  </div>
                </td>
                <td width="10%">
                  <div
                    class="form-group"
                    v-if="hasPermission((roles = ['limited_seller']))"
                  >
                    <span type="number"
                      >&dollar;
                      {{
                        (
                          parseFloat(d.price) -
                          parseFloat(getTicket(d.product_id)) / 4.5
                        ).toFixed(2)
                      }}</span
                    >
                  </div>
                </td>

                <td width="5%">
                  <div class="form-group">
                    <span type="number"
                      >%
                      {{
                        (
                          (parseFloat(d.price) /
                            parseFloat(getTicket(d.product_id))) *
                          100
                        ).toFixed(2) || null
                      }}</span
                    >
                  </div>
                </td>

                <td width="5%">
                  <button
                    type="button"
                    v-on:click="removeItem(i)"
                    class="btn btn-danger btn-sm"
                    tabindex="99"
                  >
                    <span aria-hidden="true">&minus;</span>
                  </button>
                </td>
              </tr>
            </tbody>

            <!-- Invoice Total -->
            <tfoot>
              <tr>
                <!-- <td><strong>Items:</strong> &Sigma; {{ getItemCount() }}</td> -->
                <td></td>
                <td></td>

                <td></td>
                <td></td>

                <td>
                  <span class="text-info text-bold total-underline text-danger"
                    >&dollar; {{ getTotalTicket().toLocaleString() }}</span
                  >
                </td>
                <td v-if="showOriginalPrice" colspan="2">
                  <span class="text-info text-bold total-underline text-danger">
                    {{
                      getTotalOriginalPrice() + " " + invoiceModel.currency
                    }}</span
                  >
                </td>
                <td :colspan="showOriginalPrice ? 1 : 2">
                  <span class="text-info text-bold total-underline text-danger"
                    >&dollar; {{ getTotal().toLocaleString() }}</span
                  >
                </td>
                <td v-if="hasPermission((roles = ['limited_seller']))">
                  <span class="text-info text-bold total-underline text-danger"
                    >&dollar;
                    {{ (getTotal() - getTotalTicket() / 4.5).toFixed(2) }}</span
                  >
                </td>
              </tr>
            </tfoot>
          </table>
          <!-- Genel Toplam -->
          <table class="table table-borderless table-striped">
            <tr>
              <td class="text-right" colspan="4">
                <span>Tax</span>
              </td>
              <td class="text-right">
                &dollar;
                {{ (getTotal() * (invoiceModel.tax_rate / 100)).toFixed(2) }}
              </td>
            </tr>

            <tr>
              <td class="text-right" colspan="4">
                <span>Total Sale (Sale + Tax)</span>
              </td>
              <td class="text-right">
                &dollar;
                {{
                  (getTotal() * (1 + invoiceModel.tax_rate / 100)).toFixed(2)
                }}
              </td>
            </tr>

            <tr>
              <td class="text-right" colspan="4">
                <span>Total Income (Expense+Sale)</span>
              </td>
              <td class="text-right">
                &dollar; {{ (getTotal() + getExpenseTotal()).toFixed(2) }}
              </td>
            </tr>
            <!-- add total original price -->
            <tr v-if="showOriginalPrice">
              <td class="text-right" colspan="4">
                <span>Total Original Price</span>
              </td>
              <td class="text-right">
                {{ getTotalOriginalPrice() + " " + invoiceModel.currency }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col-md-">
            <div class="row justify-content-center mt-3">
              <div class="col-md-1">
                <button
                  v-if="invoiceModel.sale_type_id === '0'"
                  type="button"
                  class="btn btn-primary"
                  @click="createProforma"
                >
                  Create Proforma Invioice
                </button>
                <button v-else id="" type="submit" class="btn btn-secondary">
                  Submit
                </button>
                <!-- <button id="form-submit" type="submit" >Submit</button> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <button id="form-submit"  type="submit" >Submit</button> -->
      </div>
    </form>
    <!-- Invoice Products END -->
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import { getAPI, postAPI, proformaExcelExport } from "@/global/callAPI";
import SelectCustomer from "@/components/SelectCustomer.vue";
import SelectProduct from "@/components/SelectProduct.vue";

export default {
  name: "NewSales",
  components: { Menu, SelectCustomer, SelectProduct },
  data: function () {
    return {
      showExcelUpload: false,
      sheetData: [],
      multiDisabled: false,
      selectKey: 0,
      outProducts: [],
      title: "New Sale",
      subTitle: "Invoice Products",
      displayBasic: false,
      autoMultiplier: null,
      multiProduct: null,
      smallMenu: false,
      customerModel: [],
      productModel: [],
      saleTypeModel: null,
      response: null,
      expenseTypeModel: null,
      storeModel: null,
      expenseModel: [
        {
          expense_type: null,
          expense_amount: null,
          description: null,
          client_or_company: "client",
        },
      ],
      invoiceModel: {
        sale_date: new Date().toLocaleDateString("fr-CA"),
        total_sale_price: 0,
        customer_id: null,
        sale_type_id: null,
        product_type: null,
        store_id: this.$store.state.store_id,
        description: null,
        tags: [],
        sale: [],
        expense: [],
        tax_rate: 0,
        currency: "USD",
      },
      productParams: "store__id=" + this.$store.state.store_id,
      multiErrorList: [],
      multiSuccessList: [],
      saleTagModel: [],
      priceType: "tag", // tag,eur, usd, aed
      exchangeRatesModel: [],
    };
  },

  created: function () {
    // getAPI('customers').then((res) => {this.customerModel = res.data.results});
    // getAPI('product-search?ordering=-product_id&limit=100&active=true').then((res) => {this.productModel = res.data.results});
    getAPI("sale-tags?limit=10000").then((res) => {
      this.saleTagModel = res.data.results;
    });

    getAPI("sale-types/?limit=10000").then((res) => {
      this.saleTypeModel = res.data.results;
    });
    getAPI("expense-types?limit=10000").then((res) => {
      this.expenseTypeModel = res.data.results;
    });
    getAPI("stores?limit=10000").then((res) => {
      this.storeModel = res.data.results;
    });
    getAPI("exchange-rates?is_last=true&limit1000").then((res) => {
      this.exchangeRatesModel = res.data.results;
    });
  },

  computed: {
    exchangeRate: function () {
      let exchangeRate = this.exchangeRatesModel.filter((rate) => {
        return rate.rate_from === this.invoiceModel.currency;
      });
      if (exchangeRate.length > 0) {
        return exchangeRate[0].exchange_rate;
      } else {
        return 1;
      }
    },
    showOriginalPrice: function () {
      if (this.invoiceModel.currency !== "USD") {
        return true;
      } else {
        return false;
      }
    },
    newProductParams: function () {
      if (this.invoiceModel.sale_type_id === "0") {
        return "";
      } else {
        return "store__id=" + this.invoiceModel.store_id;
      }
    },
  },
  methods: {
    hasPermission(roles = null) {
      if (roles === null) {
        return true;
      }

      let user_groups = this.$store.state.groups;
      if (Array.isArray(user_groups)) {
      } else {
        user_groups = user_groups.split(",");
      }
      if (user_groups.some((r) => roles.includes(r))) {
        return false;
      } else {
        return true;
      }
    },
    // Expense Methods

    addExpense: function (e) {
      e.preventDefault();
      let detail = {
        expense_type: 0,
        expense_amount: 0,
        description: null,
        client_or_company: "client",
      };
      this.invoiceModel.expense.push(detail);
    },
    removeExpense: function (id) {
      this.invoiceModel.expense.splice(id, 1);
    },
    getExpenseCount: function () {
      let c = this.invoiceModel.expense.length;
      return c;
    },
    getExpenseTotal: function () {
      let total = 0;
      for (let i = this.invoiceModel.expense.length - 1; i >= 0; i--) {
        total += parseFloat(this.invoiceModel.expense[i].expense_amount);
        this.total_expense = total;
      }
      return +total.toFixed(2);
    },

    //  Invoice Methods
    openBasic() {
      this.displayBasic = true;
    },
    closeBasic() {
      this.displayBasic = false;
    },

    multiProductAdd() {
      this.multiErrorList = [];
      this.multiSuccessList = [];
      this.closeBasic();
      var lines = this.multiProduct.split("\n");
      let query = null;
      // var olan idlerin bir daha eklenememesi için
      let usedIds = [];
      this.invoiceModel.sale.forEach((item) => {
        usedIds.push(item.product_id);
      });

      var payload = {};
      if (this.invoiceModel.sale_type_id === 9) {
        query =
          "product-search?active=false" +
          "&store__id=" +
          this.invoiceModel.store_id +
          "&reference_no=";
        payload["active"] = false;
        payload["store"] = this.invoiceModel.store_id;
      } else if (this.invoiceModel.sale_type_id === "0") {
        query = "product-search?active=true" + "&reference_no=";
      } else {
        query =
          "product-search?active=true" +
          "&store__id=" +
          this.invoiceModel.store_id +
          "&reference_no=";
        payload["active"] = true;

        payload["store"] = this.invoiceModel.store_id;
      }

      // duplicate verileri kaldırma
      lines = lines.filter((element, index) => {
        return lines.indexOf(element) === index;
      });
      payload["reference_no_list"] = lines;
      postAPI("multi-product-search", payload).then((res) => {
        res.data.results.forEach((item) => {
          if (item) {
            item["thumbnail"] = item["thumb_"];
            this.productModel.push(item);
            let detail = {
              product_id: item["product_id"],
              price: 0,
              description: null,
              multiplier: this.multiplierExpand(),
            };
            // daha önce kullanılmış id leri kaldırma
            if (usedIds.includes(item["product_id"])) {
              throw "Error";
            }
            this.invoiceModel.sale.push(detail);
            this.multiSuccessList.push(item.reference_no);
          } else {
            this.multiErrorList.push(item.reference_no);
          }
        });
        this.multiErrorList = res.data.not_found;
        this.multiplierExpand();
      });
      return;
    },
    getTicket(id, i) {
      let productobject = this.productModel;
      let targetProduct = productobject.filter((product) => {
        return product.product_id == id;
      });
      if (targetProduct.length > 0) {
        targetProduct = targetProduct[0];
        let price = targetProduct.first_price;
        if (this.priceType === "eur") {
          price = targetProduct.second_price;
        } else if (this.priceType === "usd") {
          price = targetProduct.third_price;
        } else if (this.priceType === "aed") {
          price = targetProduct.fourth_price;
        }
        return price;
      }
    },
    getPhoto(id, i) {
      let productobject = this.productModel;
      let targetProduct = productobject.filter((product) => {
        return product.product_id == id;
      });
      if (targetProduct.length > 0) {
        targetProduct = targetProduct[0];
        // if (i !== null) {
        // this.invoiceModel.sale[i]["price"] = targetProduct.first_price };
        return targetProduct.thumbnail;
      }
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    multiplierExpand() {
      if (this.autoMultiplier === null) {
        return 1;
      } else {
        this.invoiceModel.sale.forEach((item) => {
          item.multiplier = this.autoMultiplier;
          item.price = Number(
            (this.autoMultiplier * this.getTicket(item.product_id)).toFixed(2)
          );
        });

        return this.autoMultiplier;
      }
    },

    calculatePricewCurrency(id, i) {
      const exchangeRate = this.exchangeRate;
      this.invoiceModel.sale[i]["price"] = (
        this.invoiceModel.sale[i]["original_price"] * exchangeRate
      ).toFixed(2);
    },
    calculatePrice(id, i) {
      let productobject = this.productModel;
      let targetProduct = productobject.filter((product) => {
        return product.product_id == id;
      });
      if (targetProduct.length > 0) {
        targetProduct = targetProduct[0];

        let tagPrice = targetProduct.first_price;
        let listPrice = targetProduct.second_price;
        // güzel olmadı tag price e dokunmamak için yaptım
        if (this.priceType === "eur") {
          tagPrice = listPrice;
        } else if (this.priceType === "usd") {
          tagPrice = targetProduct.third_price;
        } else if (this.priceType === "aed") {
          tagPrice = targetProduct.fourth_price;
        }
        this.invoiceModel.sale[i]["price"] = (
          tagPrice * this.invoiceModel.sale[i]["multiplier"]
        ).toFixed(2);
      }
    },

    addItem: function (e) {
      let saleType = this.invoiceModel.sale_type_id;
      if (saleType === null || saleType === "") {
        alert("Please choose a sale type!");
        return true;
      }
      e.preventDefault();
      let detail = {
        product_id: null,
        price: 0,
        description: null,
        multiplier: this.multiplierExpand(),
      };

      // this.invoiceModel.sale.push(detail);
      // add as first item
      this.invoiceModel.sale.unshift(detail);
      this.selectKey += 1;
    },

    removeItem: function (id) {
      this.outProducts.splice(id, 1);
      this.invoiceModel.sale.splice(id, 1);
      this.selectKey += 1;
    },
    getItemCount: function () {
      let c = this.invoiceModel.sale.length;
      return c;
    },
    getSubTotal: function () {
      let subtotal = 0;
      for (let i = this.invoiceModel.sale.length - 1; i >= 0; i--) {
        subtotal += parseFloat(this.invoiceModel.sale[i].SubTotal);
      }
      return subtotal.toFixed(2);
    },
    getTotalTax: function () {
      let totalTax = 0;
      for (let i = this.invoiceModel.sale.length - 1; i >= 0; i--) {
        totalTax += parseFloat(this.invoiceModel.sale[i].tax_price);
      }
      return +totalTax.toFixed(2);
    },
    getTotal: function () {
      let total = 0;
      for (let i = this.invoiceModel.sale.length - 1; i >= 0; i--) {
        total += parseFloat(this.invoiceModel.sale[i].price);
      }
      this.total_sale_price = total;
      return +total.toFixed(2);
    },
    getTotalTicket: function () {
      let sum = 0;
      let productArray = this.invoiceModel.sale;

      productArray.forEach((element) => {
        let ticketPrice = this.getTicket(element.product_id);
        if (ticketPrice > 0) {
          sum += ticketPrice;
        }
      });
      return sum;
    },
    getTotalExpense: function () {
      let total = 0;
      this.expenseModel.forEach((element) => {
        let expenseAmount = element.expense_amount;
        if (expenseAmount > 0) {
          total += expenseAmount;
        }
      });
      return total;
    },

    getTotalOriginalPrice() {
      let sum = 0;
      let productArray = this.invoiceModel.sale;

      productArray.forEach((element) => {
        const originalPrice = element.original_price;
        if (originalPrice > 0) {
          sum += originalPrice;
        }
      });
      return sum;
    },

    createPost() {
      let sPros = this.invoiceModel.sale;
      let cust = this.invoiceModel.customer_id;
      let saleType = this.invoiceModel.sale_type_id;

      var arrayLength = sPros.length;
      let productControl = false;
      for (var i = 0; i < arrayLength; i++) {
        var pId = sPros[i]["product_id"];
        if (pId === null || pId === "" || pId === 0) {
          productControl = true;
        } else {
        }
      }

      if (sPros.length === 0) {
        alert("You need to add at least one product.");
        return true;
      }

      if (productControl === true) {
        alert("No product should be empty. Please check all products.");
        return true;
      }

      if (cust === null || cust === "") {
        alert("Please enter a customer.");
        return true;
      }

      if (saleType === null || saleType === "") {
        alert("Please enter sale type.");
        return true;
      }

      const customer = this.invoiceModel.customer_id;
      if (customer === null || customer === "") {
        window.alert("Please choose a customer!");
        return false;
      }
      postAPI("sales", this.invoiceModel).then((res) =>
        this.$router.push("/sales/" + res.data.sale_id)
      );
    },
    changeSaleType() {
      this.invoiceModel.sale = [];
    },

    selectProductChange(id) {
      const saleType = this.invoiceModel.sale_type_id;
      if (saleType === 9) {
        let payload = { product_id: id };
        postAPI("return-sale-info", payload).then((res) => {
          let price = res.data.price;
          let result = res.data.result;

          if (result === false) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: `Past sale of product couldn't be found!`,
              life: 2000,
            });
            return true;
          }

          for (var i = 0; i < this.invoiceModel.sale.length; i++) {
            var pId = this.invoiceModel.sale[i]["product_id"];

            if (pId === id) {
              this.invoiceModel.sale[i]["price"] = price;
              return true;
            }
          }
        });
      }
    },
    uploadMutliExcel(event) {
      const XLSX = require("xlsx");

      var files = event.target.files,
        f = files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        /* DO SOMETHING WITH workbook HERE */
        let worksheet = workbook.Sheets[sheetName];

        let sheetData = XLSX.utils.sheet_to_json(worksheet);
        this.sheetData = sheetData;
        this.showExcelUpload = true;
      }.bind(this);
      reader.readAsArrayBuffer(f);
    },

    importJson() {
      this.multiErrorList = [];
      this.multiSuccessList = [];

      // var olan idlerin bir daha eklenememesi için
      let usedIds = [];
      this.invoiceModel.sale.forEach((item) => {
        usedIds.push(item.product_id);
      });

      this.closeBasic();
      let sheetData = this.sheetData;

      let query = null;
      if (this.invoiceModel.sale_type_id === 9) {
        query =
          "product-search?active=false" +
          "&store__id=" +
          this.invoiceModel.store_id +
          "&reference_no=";
      } else if (this.invoiceModel.sale_type_id === "0") {
        query = "product-search?" + "&reference_no=";
      } else {
        query =
          "product-search?active=true" +
          "&store__id=" +
          this.invoiceModel.store_id +
          "&reference_no=";
      }

      for (var index = 0; index < sheetData.length; index++) {
        const id = sheetData[index]["reference no"];
        let price = parseFloat(sheetData[index]["price"]);
        if (typeof price != "number") {
          price = null;
        }
        const description = sheetData[index]["description"];
        // boş id leri kontrol etme
        if (/^\s*$/.test(id) === true || id === null) {
          continue;
        }

        try {
          getAPI(query + id)
            .then((res) => {
              if (res.data.results[0]) {
                this.productModel.push(res.data.results[0]);
                let detail = {
                  product_id: res.data.results[0]["product_id"],
                  price: price,
                  description: description,
                  multiplier: this.multiplierExpand(),
                };

                // daha önce kullanılmış id leri kaldırma
                if (usedIds.includes(res.data.results[0]["product_id"])) {
                  throw "Error";
                }

                this.invoiceModel.sale.push(detail);
                this.multiSuccessList.push(id);
              } else {
                this.multiErrorList.push(id);

                // this.$toast.add({
                //   severity: "error",
                //   summary: "Error",
                //   detail: `${id} doesn't added!`,
                //   life: 2000,
                // });
              }
            })
            .then((element) => {
              this.removeDuplicates();
            });
        } catch (error) {
          this.multiErrorList.push(id);

          // this.$toast.add({
          //   severity: "error",
          //   summary: "Error",
          //   detail: `${id} doesn't added!`,
          //   life: 2000,
          // });
        }
      }
      this.showExcelUpload = false;
      this.sheetData = [];
    },

    removeDuplicates() {
      var seenNames = {};
      let array = this.invoiceModel.sale;
      array = array.filter(function (currentObject) {
        if (currentObject.product_id in seenNames) {
          return false;
        } else {
          seenNames[currentObject.product_id] = true;
          return true;
        }
      });
      this.invoiceModel.sale = array;
    },

    downloadSaleTemplate() {
      const XLSX = require("xlsx");
      // let data = this.tableData

      let exportArray = [
        { "reference no": null, price: null, description: null },
      ];
      let binary_univers = exportArray;
      let binaryWS = XLSX.utils.json_to_sheet(binary_univers);

      // Create a new Workbook
      var wb = XLSX.utils.book_new();

      // Name your sheet
      XLSX.utils.book_append_sheet(wb, binaryWS, "Template");

      // export your excel

      XLSX.writeFile(wb, "Sale Import Template" + ".xlsx");
      this.exportLoading = false;
    },

    storeChange() {
      this.invoiceModel.sale = [];
    },
    tagConverter(id) {
      return { tag: id };
    },

    retriveLastSaleData() {
      const idArray = [];
      const saleData = this.invoiceModel.sale;

      saleData.forEach((product) => {
        idArray.push(product.product_id);
      });

      const payload = { products: idArray };
      console.log(payload);
      postAPI("last-sale-product-data", payload)
        .then((response) => {
          const sale_data = response.data.results;
          sale_data.forEach((item) => {
            const price = item.price;
            const description = item.description;
            const product_id = item.product_id;
            for (let i = 0; i < this.invoiceModel.sale.length; i++) {
              if (this.invoiceModel.sale[i].product_id === product_id) {
                this.invoiceModel.sale[i].price = price;
                this.invoiceModel.sale[i].description = description;
                break;
              }
            }
          });
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: `Total number of product:${idArray.length} \nNumber of products found:${response.data.item_number}`,
            life: 3000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: `Please try again.`,
            life: 2000,
          });
        });
    },
    taxRateChange() {
      if (
        this.invoiceModel.tax_rate === null ||
        this.invoiceModel.tax_rate === undefined ||
        this.invoiceModel.tax_rate === ""
      ) {
        this.invoiceModel.tax_rate = 0;
      }
    },
    createProforma() {
      //control customer
      if (
        this.invoiceModel.customer_id === null ||
        this.invoiceModel.customer_id === undefined ||
        this.invoiceModel.customer_id === ""
      ) {
        window.alert("Please select a customer");
        return;
      }

      const payload = this.invoiceModel;
      proformaExcelExport(payload).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));

        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Proforma Invoice.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();

        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: `Proforma created.`,
          life: 3000,
        });
      });
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  margin: 0 auto;
  padding: 9px;
  color: #fff;
}

.text-bold {
  font-weight: 500;
}

.total-underline {
  border-bottom: 3px solid black;
  float: right;
}

hr {
  border-top: 1px solid black;
}

.btn.btn-danger.btn-sm {
  display: block;
  margin: auto;
}

#form-submit {
  background-color: black;
  width: 120px;
  margin-left: 240 px;
  margin-right: auto;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  position: absolute;
  margin-top: 20px;
  left: 50%;
}

#invoice-table {
  background-color: white !important;
}

input {
  border-radius: 0px;
}

select {
  border-radius: 0px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
