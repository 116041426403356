<template>
  <v-select
    :options="products"
    label="reference_no"
    @search="onSearch"
    v-model="selected"
    @option:selected="afterSelected"
    :reduce="(product) => product.product_id"
    :disabled="disabled"
  >
    <!-- Seçtikten sonra reference no resetleniyordu bu yüzden böyle bir sistem yaptım -->
    <template #selected-option="{}">
      <div style="display: flex; align-items: baseline">
        <p>{{ selectedReference }}</p>
      </div>
    </template>
  </v-select>
</template>

<script>
import { getAPI } from "../global/callAPI";
export default {
  name: "ProductSelectDeneme",
  props: {
    selected: null,
    productModel: {
      type: Array,
      default: null,
    },
    active: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outProducts: {
      type: Array,
      default: [],
    },
    extraParams: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      // selected: 24,
      products: [],
      // productModel: [],
      selectedReference: null,
    };
  },

  created: function () {
    let selectedId = this.selected;
    let active = "active=" + this.active + "&";
    // getAPI('product-search?limit=5&ordering=-product_id&'+active).then((res) =>
    // {
    // this.products = res.data.results;
    if (selectedId > 0) {
      let fil = this.productModel.find(
        (item) => item.product_id === selectedId
      );
      if (fil) {
        this.selectedReference = fil.reference_no;
        this.products.push(fil);
        // this.productModel.forEach(pro=>{this.products.push(pro)})
      } else {
        getAPI("product-search/" + selectedId).then((res) => {
          if (this.products.indexOf(res.data) === -1) {
            this.products.push(res.data);
            this.selectedReference = res.data.reference_no;
          }
        });
      }
    }
    this.products.forEach((product) => {
      this.productModel.push(product);
      this.goOut();

      // })
      // this.productModel.push(this.products)
      // this.productModel =  [].concat.apply([], this.productModel)
    });
  },
  methods: {
    onSearch(query) {
      let request = "search=" + query;
      let active = "active=" + this.active + "&";
      if (this.active === null) {
        active = "";
      }
      let extra = "&" + this.extraParams;
      getAPI(
        "product-search?limit=5&ordering=-product_id&" +
          active +
          request +
          extra
      ).then((res) => {
        this.products = res.data.results;
        this.goOut();
      });
    },
    afterSelected() {
      let id = this.selected;
      getAPI("product-search/" + id).then((res) => {
        this.productModel.push(res.data);
        // this.productModel =  [].concat.apply([], this.productModel)
        this.selectedReference = res.data.reference_no;
        this.outProducts.push(res.data.product_id);
        this.goOut();
      });
    },

    goOut() {
      let outProducts = this.outProducts;

      if (outProducts.length > 0) {
        // this.productModel = this.productModel.filter(item=>{!outProducts.includes(item["product_id"])})

        var i = 0;
        while (i < this.products.length) {
          if (outProducts.includes(this.products[i]["product_id"])) {
            this.products.splice(i, 1);
          } else {
            ++i;
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
