<template>
  <div class="row"></div>

  <div
    class="row justify-content-center d-flex flex-row align-items-center mt-2"
  ></div>

  <div class="row justify-content-center mt-0">
    <div class="row justify-content-center">
      <div class="col-md-6 offset-md-0">
        <table class="table table-bordered" v-if="showSumTable === true">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Sale</th>
              <th scope="col">Consignee</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row justify-content-center">Total</th>
              <td>
                {{
                  formatCurrency(
                    tableData.completed_total_net_sale +
                      tableData.completed_total_expense +
                      tableData.awaiting_net_sale +
                      tableData.awaiting_total_expense
                  )
                }}
              </td>
              <td>
                {{
                  formatCurrency(
                    tableData.consignee_total_net_sale +
                      tableData.consignee_total_expense
                  )
                }}
              </td>
              <td>
                {{
                  formatCurrency(
                    tableData.consignee_total_net_sale +
                      tableData.completed_total_net_sale +
                      tableData.awaiting_net_sale +
                      tableData.awaiting_total_expense +
                      tableData.completed_total_expense +
                      tableData.consignee_total_expense
                  )
                }}
              </td>
            </tr>
            <tr>
              <th scope="row justify-content-center">Return</th>
              <td>
                {{
                  formatCurrency(
                    tableData.sale_return_total_net_sale +
                      tableData.return_total_expense
                  )
                }}
              </td>
              <td>
                {{ formatCurrency(tableData.consignee_return_total_net_sale) }}
              </td>
              <td>
                {{
                  formatCurrency(
                    tableData.consignee_return_total_net_sale +
                      tableData.sale_return_total_net_sale +
                      tableData.return_total_expense
                  )
                }}
              </td>
            </tr>
            <tr>
              <th scope="row justify-content-center">Net</th>
              <td>
                {{
                  formatCurrency(
                    tableData.completed_total_net_sale +
                      tableData.completed_total_expense +
                      tableData.awaiting_net_sale +
                      tableData.awaiting_total_expense -
                      (tableData.sale_return_total_net_sale +
                        tableData.return_total_expense)
                  )
                }}
              </td>
              <td>
                {{
                  formatCurrency(
                    tableData.consignee_total_net_sale +
                      tableData.consignee_total_expense -
                      tableData.consignee_return_total_net_sale
                  )
                }}
              </td>
              <td>
                {{
                  formatCurrency(
                    tableData.consignee_total_net_sale +
                      tableData.consignee_total_expense -
                      tableData.consignee_return_total_net_sale +
                      (tableData.completed_total_net_sale +
                        tableData.awaiting_net_sale -
                        (tableData.sale_return_total_net_sale +
                          tableData.return_total_expense))
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- açıklama ekle -->
        <p class="text-muted">
          * Consignee-Return cell displays net sale (excluding expenses) but
          other cells show total sale (including expenses).
        </p>
      </div>
      <!-- <div class="offset-md-1 col-md-4 align-self-center">
            <p> Total Completed: {{formatCurrency(priceSum.totalCompleted)}}</p>
            <p> Total Uncompleted: {{formatCurrency(priceSum.totalAwaiting)}}</p>
            <p>Paid: {{formatCurrency(priceSum.paidAwaiting)}}</p>
            <p>Awaiting Price: {{formatCurrency(priceSum.totalAwaiting - priceSum.paidAwaiting)}}</p>
        </div> -->
    </div>
    <div class="row justify-content-end">
      <div class="col-md-2 d-flex align-items-center justify-content-center">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="showAllHistory"
            @change="filterUpdate"
          />
          <label class="form-check-label" for="">
            Show All Histoy <small>(Only for Sale Product)</small></label
          >
        </div>
      </div>
      <div class="justify-content-end col-md-2">
        <label for="saleStatus">Sale Status</label>
        <select
          v-model="sale_status"
          id="saleStatus"
          class="form-select"
          @change="filterUpdate"
        >
          <option value="consignee,awaiting,completed,canceled,return">
            All
          </option>
          <option value="completed">Completed</option>
          <option value="awaiting">Awaiting</option>
          <option value="consignee">Consignee</option>
          <option value="canceled">Canceled</option>
        </select>
      </div>
    </div>
    <!-- <div v-if="loading === true"  class="row justify-content-center justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div> -->

    <div class="col-md-12">
      <!-- <DataTable
                    :loading="loading"
                    class="p-datatable-sm"
                    selectionMode="single"             
                    @row-dblclick="row justify-content-centerClick($event)"
                    :value="tableData" 
                    :paginator="true"
                    :rows="10"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[10,20,50]"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    responsiveLayout="scroll">
                <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"></Column>
            
            </DataTable> -->

      <Table
        v-show="showTable && activeIndex === 1"
        excelName="customer-sales"
        excelTabName="Sales"
        :endPoint="endPoint"
        :tableInfo="tableInfo"
        :useCreate="true"
        showRoute="true"
        detailPath="/sales/"
        idName="sale_id"
        :key="saleKey"
      />

      <Table
        v-show="showTable && activeIndex === 2"
        excelName="customer-sale-products"
        excelTabName="Sales"
        :endPoint="saleProductsEndPoint"
        :useCreate="true"
        :tableInfo="productsTableInfo"
        showRoute="true"
        detailPath="/products/"
        idName="product_id"
        :key="saleProductKey"
      />
    </div>
  </div>
</template>

<script>
import Table from "@/components/Table";
import { getAPI } from "@/global/callAPI";
export default {
  name: "CustomerItemSales",
  components: { Table },

  data: function () {
    return {
      showAllHistory: false,
      showSumTable: false,
      tableData: {
        awaiting_net_sale: 0,
        canceled_total_net_sale: 0,
        completed_total_net_sale: 0,
        consignee_return_total_net_sale: 0,
        consignee_total_net_sale: 0,
        return_total_net_sale: 0,
        sale_return_total_net_sale: 0,
        completed_total_expense: 0,
        return_total_expense: 0,
        awaiting_total_expense: 0,
        canceled_total_expense: 0,
        consignee_total_expense: 0,
      },
      showTable: false,
      tableMode: "Sales",
      saleProducts: [],
      saleProductsEndPoint: null,
      tableInfo: [
        { field: "sale_date", header: "Date" },
        { field: "sale_reference", header: "Sale Reference" },

        { field: "sale_type", header: "Sale Type" },
        { field: "sale_status", header: "Status" },
        { field: "store_name", header: "Store" },
        { field: "seller", header: "Seller" },
        { field: "total_sale_price", header: "Price" },
      ],
      productsTableInfo: [
        { field: "thumbnail", header: "Photo", isImage: true },
        { field: "sale_date", header: "Date" },
        { field: "reference_no", header: "Reference No" },
        { field: "sale_reference", header: "Sale Reference" },
        { field: "sale_status", header: "Status" },
        { field: "store", header: "Store" },
        { field: "seller", header: "Seller" },
        { field: "ticket_price", header: "Ticket Price" },
        { field: "price", header: "Price" },
      ],
      loading: false,
      columns: null,
      results: null,
      filters: {
        Consignee: true,
        Return: true,
        Nakit: true,
        Vadeli: true,
      },
      priceSum: {
        totalSale: 0,
        totalReturn: 0,
        totalConsignee: 0,
        totalSaleReturn: 0,
        totalConsigneeReturn: 0,
        netSale: 0,
        netConsignee: 0,

        totalAwaiting: 0,
        paidAwaiting: 0,
        totalCompleted: 0,
      },

      sale_status: "consignee,awaiting,completed,canceled,return",
      saleKey: 0,
      saleProductKey: 0,
    };
  },
  props: {
    id: {
      default: null,
    },
    activeIndex: {
      default: 1,
    },
    // tableData: {
    //     default:[]
    // }
  },
  methods: {
    rowClick(e) {
      this.$router.push({ path: "/sales/" + e.data.sale_id });
    },
    formatCurrency(value) {
      if (value) {
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      } else {
        return 0;
      }
    },
    applyFilter() {
      const filterModel = this.filters;

      let filterList = [];
      for (const key in filterModel) {
        if (filterModel[key] == true) {
          filterList.push(key);
        }
      }
      this.results = this.sales.filter((sale) =>
        filterList.includes(sale.sale_type)
      );
      this.tableData = this.results;
      this.$forceUpdate();
    },

    hasPermission(roles = null) {
      if (roles === null) {
        return true;
      }
      let user_groups = this.$store.state.groups;
      if (Array.isArray(user_groups)) {
      } else {
        user_groups = user_groups.split(",");
      }
      if (user_groups.some((r) => roles.includes(r))) {
        return false;
      } else {
        return true;
      }
    },
    permission(n) {
      return localStorage.getItem("groups").indexOf(n) > -1;
    },

    filterUpdate() {
      let isLastFilter = `&is_last=${!this.showAllHistory || ""}`;

      let statusFilter = `&sale_id__sale_status__in=${
        this.sale_status || null
      }`;
      let saleStatus = `&sale_status__in=${this.sale_status || null}`;
      this.saleProductsEndPoint =
        "sale-products?" +
        "&sale_id__customer_id__id=" +
        this.id +
        statusFilter +
        isLastFilter;
      this.endPoint = "sales?" + "&customer_id__id=" + this.id + saleStatus;
      this.saleKey += 1;
      this.saleProductKey += 1;
      getAPI(
        "sum-sale-customers?" +
          "&sale_id__customer_id__id=" +
          this.id +
          statusFilter +
          isLastFilter
      ).then((res) => {
        this.sales = res.data;
        this.tableData = this.sales;
        this.showSumTable = true;
      });
    },
  },

  created: function () {
    this.endPoint = "sales?" + "&customer_id__id=" + this.id;

    this.saleProductsEndPoint =
      "sale-products?" +
      "&sale_id__customer_id__id=" +
      this.id +
      `&is_last=${!this.showAllHistory || ""}&sale_id__sale_status__in=${
        this.sale_status || null
      }`;
    this.loading = true;
    this.loading = false;
    this.showTable = true;

    let roles = ["limited_seller"];
    if (this.hasPermission((roles = roles))) {
      this.tableInfo.splice(6, 0, { field: "total_profit", header: "Profit" });
      this.productsTableInfo.splice(6, 0, {
        field: "profit",
        header: "Profit",
      });
    }

    let statusFilter = `&sale_id__sale_status__in=${this.sale_status || null}`;
    getAPI(
      "sum-sale-customers?" +
        "&sale_id__customer_id__id=" +
        this.id +
        statusFilter
    ).then((res) => {
      this.sales = res.data;
      this.tableData = this.sales;
      this.showSumTable = true;
    });
  },
};
</script>

<style></style>
