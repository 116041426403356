<template>
  <Menu />
  <div class="container-fluid">
    <h6
      class="text-center font-weight-bold text-info text-dark"
      style="font-size: 15px"
    >
      Import Product Price
    </h6>
    <hr />
    <div class="row mt-3">
      <div class="col-md-6">
        <input
          type="file"
          @change="handleFileSelection"
          accept=".xlsx, .xls"
          class="form-control"
        />
      </div>
      <div class="col-md-6">
        <button
          class="btn btn-primary"
          :disabled="!selectedFile || loading"
          @click="processSelectedFile"
        >
          <span v-if="loading" class="spinner-border spinner-border-sm"></span>
          <span v-else>Process File</span>
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <p class="text-muted">
          Please upload an Excel file (.xlsx or .xls) with the following
          columns:
        </p>
        <ul>
          <li>
            <strong>Reference</strong> (Required): The unique identifier for the
            product. Must be a string.
          </li>
          <li>
            <strong>TAG PRICE</strong>: The price in the local currency. Must be
            a number.
          </li>
          <li><strong>EURO</strong>: The price in Euros. Must be a number.</li>
          <li>
            <strong>USD</strong>: The price in US Dollars. Must be a number.
          </li>
          <li>
            <strong>AED</strong>: The price in UAE Dirhams. Must be a number.
          </li>
        </ul>
        <p class="text-danger">
          Note: The "Reference" column is mandatory, and at least one additional
          column must be filled. Ensure that the data types are correct.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { read, utils } from "xlsx";
import { postAPI } from "@/global/callAPI";
import Menu from "@/components/Menu";

export default {
  components: { Menu },
  data() {
    return {
      selectedFile: null,
      processedData: [],
      loading: false, // Add loading state
    };
  },
  methods: {
    handleFileSelection(event) {
      this.selectedFile = event.target.files[0];
    },

    processSelectedFile() {
      if (!this.selectedFile) {
        alert("Please select a file.");
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = utils.sheet_to_json(sheet);

        // Validate and process the data
        this.processExcelData(jsonData);
      };
      reader.readAsArrayBuffer(this.selectedFile);
    },

    processExcelData(data) {
      if (!data || data.length === 0) {
        alert("The Excel file is empty.");
        return;
      }

      const requiredColumn = "Reference";
      const columnMapping = {
        Reference: "reference_no",
        "TAG PRICE": "first_price",
        EURO: "second_price",
        USD: "third_price",
        AED: "fourth_price",
      };

      // Check if the required column exists
      if (!data[0][requiredColumn]) {
        alert(`The "${requiredColumn}" column is required.`);
        return;
      }

      // Check if at least one column (besides Reference) exists
      const hasAtLeastOneColumn = data.some((row) =>
        Object.keys(row).some(
          (key) =>
            key !== requiredColumn &&
            row[key] !== undefined &&
            row[key] !== null &&
            row[key] !== ""
        )
      );

      if (!hasAtLeastOneColumn) {
        alert("At least one additional column is required.");
        return;
      }

      // Validate data types and map the columns
      this.processedData = data
        .map((row) => {
          const mappedRow = {};
          for (const [key, value] of Object.entries(columnMapping)) {
            if (key === "Reference") {
              // Ensure Reference is a string
              if (typeof row[key] !== "string") {
                alert(`Invalid data type for "${key}". It must be a string.`);
                return null;
              }
              mappedRow[value] = row[key];
            } else {
              // Ensure other columns are numbers
              if (
                row[key] !== undefined &&
                row[key] !== null &&
                row[key] !== ""
              ) {
                if (isNaN(row[key])) {
                  alert(`Invalid data type for "${key}". It must be a number.`);
                  return null;
                }
                mappedRow[value] = parseFloat(row[key]);
              } else {
                mappedRow[value] = null;
              }
            }
          }
          return mappedRow;
        })
        .filter(Boolean); // Remove invalid rows

      if (this.processedData.length === 0) {
        alert("No valid data found in the file.");
        return;
      }

      // Show confirmation using native alert
      const confirmationMessage = this.processedData
        .map(
          (product) =>
            `Reference: ${
              product.reference_no
            } will be updated with TAG PRICE: ${
              product.first_price || "N/A"
            }, EURO: ${product.second_price || "N/A"}, USD: ${
              product.third_price || "N/A"
            }, AED: ${product.fourth_price || "N/A"}`
        )
        .join("\n");

      const userConfirmed = window.confirm(
        `The following changes will be made:\n\n${confirmationMessage}\n\nDo you want to proceed?`
      );

      if (userConfirmed) {
        this.confirmChanges();
      }
    },

    async confirmChanges() {
      const endpoint = "import-product-price";
      const payload = { products: this.processedData };

      this.loading = true; // Start loading
      try {
        const res = await postAPI(endpoint, payload);
        alert("Product prices have been successfully imported.");
        console.log("Response:", res);
      } catch (err) {
        alert("An error occurred while importing product prices.");
        console.error("Error:", err);
      } finally {
        this.loading = false; // Stop loading
      }
    },
  },
};
</script>
